import styled from "styled-components";

const Button = styled.button`
  width: 206px;
  height: 57px;
  background-color: ${({ color }) => color || '#f59e0c'};
  border-radius: 50px 50px 50px 50px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  /* Mobile */
  @media screen and (max-width: 900px) {
    height: 40px;
    font-size: 14px;
  }
`;

export const ButtonAction = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 800;
  width: 282px;
  height: 67px;
  font-size: 20px;

  & img {
    width: 33px !important;
  }
`;

export default Button;