import React from "react";
import styled from "styled-components";

const BoldedSmallText = styled.p`
  font-size: 26px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
`;

const BuyButton = styled.a`
  width: 206px;
  height: 57px;
  border-radius: 50px 50px 50px 50px;
  border: 2px solid #000;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const SectionItemsWrapper = styled.div`
  overflow-x: hidden;
  display: grid;
  background-color: #fff;
  color: #000;
  padding: 37px 4%;
  grid-template-columns: auto auto;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 1fr;

    & ${ButtonWrapper} {
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    & ${ButtonWrapper} {
      flex-direction: column;
    }
  }
`;

export default function FalloutSectionBuy() {
  return (
    <SectionItemsWrapper>
      <div>
        <BoldedSmallText>BUY A FALLOUT FREAK</BoldedSmallText>
      </div>
      <ButtonWrapper>
        <BuyButton href="https://looksrare.org/collections/0x7E6Bc952d4b4bD814853301bEe48E99891424de0">
          BUY ON LOOKSRARE
        </BuyButton>
        <BuyButton href="https://opensea.io/collection/jungle-freaks-genesis">
          BUY ON OPENSEA
        </BuyButton>
      </ButtonWrapper>
    </SectionItemsWrapper>
  );
}
