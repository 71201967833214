import styled from "styled-components";
import ITEM1 from "../static/images/fallout-item-1.png";
import ITEM2 from "../static/images/fallout-item-2.png";
import ITEM3 from "../static/images/fallout-item-3.png";
import ITEM4 from "../static/images/fallout-item-4.png";
import ITEM5 from "../static/images/fallout-item-5.png";
import ITEM6 from "../static/images/fallout-item-6.png";

const Container = styled.div`
  display: grid;
  background: #000;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  & img {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function FalloutSectionItems() {
  return (
    <Container>
      <img src={ITEM4} alt="" />
      <img src={ITEM2} alt="" />
      <img src={ITEM6} alt="" />
      <img src={ITEM5} alt="" />
    </Container>
  );
}