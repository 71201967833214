import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import MUTE from "../static/images/icons/mute.svg";
import UNMUTE from "../static/images/icons/unmute.svg";
import { Stream } from "@cloudflare/stream-react";

const MuteIcon = styled.img`
  position: absolute;
  bottom: 7px;
  right: 15px;
  width: 30px;
`;

const McModal = ({ onClose, videoId }) => {
  const [mute, setMute] = useState(false);
  const ref = React.useRef(null);
  
  const toggleVideoMute = (muted) => {
    ref.current.muted = muted;
    setMute(muted);
  };

  const muteIcon = mute ? (
    <MuteIcon onClick={() => toggleVideoMute(false)} src={UNMUTE} />
  ) : (
    <MuteIcon onClick={() => toggleVideoMute(true)} src={MUTE} />
  ); 

  return (
    <Modal
      onClose={onClose}
      bodyStyle={{ background: '#000', minHeight: '140px'}}
    >
      <Stream 
        streamRef={ref}
        src={videoId}
        autoplay={true}
        controls={false}
        preload={true}
        responsive={true}
      />
      {muteIcon}
    </Modal> 
  );
};

export default McModal;