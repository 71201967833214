import React from "react";
import styled from "styled-components";
import RADIOATIVE from "../static/images/radioative.png";
import EQUAL from "../static/images/fallout-equal.png";
import PLUS from "../static/images/fallout-plus.png";
import STONE from "../static/images/fallout-mutation-stone.png";
import FROM from "../static/images/fallout-mutation-from.png";
import TO from "../static/images/fallout-mutation-to.png";

const Title = styled.div`
  font-size: 40px;
  color: #05fe01;
  span {
    color: #fff;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: #fff;
  margin-top: 40px;
  * {
    font-family: "IBM Plex Mono Regular", sans-serif !important;
  }
  p {
    margin-top: 30px;
  }
  b {
    font-family: "IBM Plex Mono Bold", sans-serif !important;
  }
  ul {
    margin-top: 2px;
  }
`;

const MutationCalc = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    max-width: 100%;
  }
`;

const MutationCalcImg = styled.div`
  width: 25%;
  max-width: 350px;
`;

const MutationCalcSinal = styled.div`
  width: 12.5%;
  text-align: center;
  align-self: center;
  img {
    max-width: 80px;
    max-width: min(80px, 50%);
  }
`;

const MutationCalcTitle = styled.div`
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
  font-family: "IBM Plex Mono Regular", sans-serif !important;
`;

const RadioativeIcon = styled.div`
  text-align: center;
  margin: 50px 0;
  img {
    width: 76px;
  }
`;

const Container = styled.div`
  gap: 30px;
  padding: 40px 8%;

  @media screen and (max-width: 900px) {
    ${MutationCalc} {
      flex-direction: column;
      align-items: center;
    }
    ${MutationCalcImg}, ${MutationCalcSinal} {
      width: 90%;
    }
    ${MutationCalcSinal} {
      padding: 60px 0;
    }
  }
`;

export default function FalloutSectionNextSteps() {
  return (
    <>
      <Container>
        <Title>
          EVOLVE YOUR JUNGLE FREAK <span>&#47;&#47; NEXT STEPS</span>
        </Title>
        <Description>
          <p>
            <b>TEST SUBJECT MINT:</b> 5K supply - discounts for JF Genesis & JF
            Motor Club Holders
            <ul>
              <li>
                <b>Whitelist Mint:</b> 5/25 10am EST - 10pm EST
              </li>
              <li>
                <b>Public Mint:</b> 5/25 10pm EST - 5/26 10am EST
              </li>
              <li>
                <b>Reveal:</b> June 4th @ 10am EST
              </li>
            </ul>
          </p>
          <p>
            <b>FALLOUT CRYSTAL MINT:</b> 10K supply - for JF Genesis Holders.
            Special Pricing for $JUNGLE holders.
            <ul>
              <li>
                <b>Phase 1 Mint:</b> 5/26 10am EST - 5/27 10pm EST
              </li>
              <li>
                <b>Phase 2 Mint:</b> 5/27 10pm EST - 5/28 10am EST
              </li>
              <li>
                <b>Reveal:</b> IMMEDIATELY after Mint
              </li>
            </ul>
          </p>
          <p>
            <b>CRYSTAL EXPOSURE:</b> Expose your Jungle Freak to Fallout
            Crystals. Burn Fallout Crystals. Mint Fallout Freaks.
            <ul>
              <li>
                <b>Begins:</b> June 3rd @ 10am EST. No deadline.
              </li>
              <li>
                <b>Reveal:</b> IMMEDIATELY after burning Fallout Crystals.
              </li>
            </ul>
          </p>
        </Description>
        <MutationCalc>
          <MutationCalcImg>
            <img src={FROM} alt="" />
            <MutationCalcTitle>OG JUNGLE FREAK</MutationCalcTitle>
          </MutationCalcImg>
          <MutationCalcSinal>
            <img src={PLUS} alt="" />
          </MutationCalcSinal>
          <MutationCalcImg>
            <img src={STONE} alt="" />
            <MutationCalcTitle>
              MUTATION CRYSTAL
              <br />I • II • III
            </MutationCalcTitle>
          </MutationCalcImg>
          <MutationCalcSinal>
            <img src={EQUAL} alt="" />
          </MutationCalcSinal>
          <MutationCalcImg>
            <img src={TO} alt="" />
            <MutationCalcTitle>
              FALLOUT FREAK
              <br />
              with UPDATED MATCHING TRAITS
            </MutationCalcTitle>
          </MutationCalcImg>
        </MutationCalc>
        <RadioativeIcon>
          <img src={RADIOATIVE} alt="" />
        </RadioativeIcon>
      </Container>
    </>
  );
}
