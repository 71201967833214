import styled from "styled-components";

const Heading = styled.div`
  font-size: 34px;
  font-weight: 800;
  color: white;
  padding: 50px 0;
  text-align: ${({ align }) => align || 'left'};
  ${({ border }) => border && 'border-top: 1px #ffffff8c solid;'}
  grid-area: item-sec-6-title;
  font-family: "Montserrat", sans-serif;
`;

export default Heading;