import styled from "styled-components";
import Footer from "../components/Footer";
import FalloutHero from "../components/FalloutHero";
import Navbar from "../components/Navbar";
import BG from "../static/images/fallout-hero-bg.png";
import SectionPartners from "../components/SectionPartners";
import FalloutSectionBuy from "../components/FalloutSectionBuy";
import FalloutSectionItems from "../components/FalloutSectionItems";
import LINE_RADIOATIVE from "../static/images/line-radioative.png";
import FalloutSectionWhatIs from "../components/FalloutSectionWhatIs";
import FalloutSectionNextSteps from "../components/FalloutSectionNextSteps";
import FalloutSectionExcavation from "../components/FalloutSectionExcavation";

const Overlay = styled.div`
  background: #121212;
  & * {
    font-family: 'Furore', sans-serif;
  }
`;

const Container = styled.div`
  background: url("${BG}");
  background-repeat: no-repeat;
  background-size: 100%;

  #partners {
    border-bottom: 1px solid #707070;
  }
`;

const LineRadioative = styled.div`
  width: 100%;
  height: 40px;
  background-size: 1935px 39px;
  background-position: -55px top;
  background-repeat: repeat-x;
  background-image: url("${LINE_RADIOATIVE}");
`;

const FalloutCountdown = () => {
  return (
    <Overlay>
      <Container>
        <header>
          <Navbar absolute={true} />
          <FalloutHero />
        </header>
        <FalloutSectionWhatIs />
        <FalloutSectionExcavation />
        <FalloutSectionNextSteps />
        <LineRadioative />
        <FalloutSectionItems />
        <FalloutSectionBuy />
        <SectionPartners bg="transparent" />
        <Footer bg="transparent" cbBg="#05FE01" cbColor="#000" />
      </Container>
    </Overlay>
  );
};

export default FalloutCountdown;
