import React from "react";
import styled from "styled-components";
import STONE1 from "../static/images/fallout-stone-1.png";
import STONE2 from "../static/images/fallout-stone-2.png";
import STONE3 from "../static/images/fallout-stone-3.png";

const Grid = styled.div`
  display: flex;
  gap: 4.5%;
`;

const Item = styled.div`
  width: 33%;

  img {
    width: 100%;
  }
`;

const Title = styled.div`
  color: #05fe01;
  font-size: 40px;
  margin-bottom: 50px;
`;

const ItemTitle = styled.div`
  color: #05fe01;
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  margin: 5px 0;
  font-family: "IBM Plex Mono Regular", sans-serif !important;
`;

const Subtitle2 = styled.div`
  font-size: 16px;
  color: #939393;
  font-weight: normal;
  font-family: "IBM Plex Mono Regular", sans-serif !important;
`;

const Container = styled.p`
  display: flex;
  flex-direction: column;
  padding: 60px 8%;

  @media screen and (max-width: 900px) {
    padding-bottom: 0;
    ${Grid} {
      flex-direction: column;
      align-items: center;
    }
    ${Item} {
      width: 90%;
      margin-bottom: 70px;
    }
  }
`;

export default function FalloutSectionExcavation() {
  return (
    <Container>
      <Title>FALLOUT Crystal Excavation</Title>
      <Grid>
        <Item>
          <img src={STONE1} alt="" />
          <ItemTitle>
            Tier 1<Subtitle>FALLOUT Crystal</Subtitle>
            <Subtitle2>&lt;common&gt;</Subtitle2>
          </ItemTitle>
        </Item>
        <Item>
          <img src={STONE2} alt="" />
          <ItemTitle>
            Tier II<Subtitle>FALLOUT Crystal</Subtitle>
            <Subtitle2>&lt;rare&gt;</Subtitle2>
          </ItemTitle>
        </Item>
        <Item>
          <img src={STONE3} alt="" />
          <ItemTitle>
            Tier III<Subtitle>FALLOUT Crystal</Subtitle>
            <Subtitle2>&lt;legendary&gt;</Subtitle2>
          </ItemTitle>
        </Item>
      </Grid>
    </Container>
  );
}
