import styled from "styled-components";

const HeroTitle = styled.div`
  font-size: 80px;
  font-weight: 800;
  color: white;
  margin-top: 4%;
  font-family: "Montserrat", sans-serif;

  & small {
    font-weight: 500;
    font-size: 42px;
    display: block;
  }
  & span {
    font-weight: normal;
  }

  @media screen and (max-width: 900px) {
    font-size: 65px;
    margin-top: 4%;
    text-align: left;
    & small {
        font-size: 38px;
    }
  }

  @media screen and (max-width: 680px) {
    font-size: 35px;
    margin-top: 7%;
    & small {
      font-size: 20px;
    }
  }
`;

export default HeroTitle;