import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FalloutSectionBuy from "../components/FalloutSectionBuy";
import FalloutSectionItems from "../components/FalloutSectionItems";
import BG from "../static/images/fallout-hero-bg.png";
import LOGO from "../static/images/fallout-logo2.png";
import RADIOATIVE from "../static/images/radioative.png";
import RADIOATIVE2 from "../static/images/radioative2.png";
import LINE_RADIOATIVE from "../static/images/line-radioative.png";

const Centered = styled.div`
  width: 90vw;
  max-width: 756px;
  height: 90vw;
  max-height: 756px;
  background: transparent url("${RADIOATIVE2}");
  background-repeat: no-repeat;
  background-size: 84%;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const LineRadioative = styled.div`
  width: 100%;
  height: 40px;
  background-size: 1935px 39px;
  background-position: -55px top;
  background-repeat: repeat-x;
  background-image: url("${LINE_RADIOATIVE}");
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 40px;
  text-align: center;
`;

const RadioativeImg = styled.img`
  width: 52px;
  height: 52px;
`;

const TimeWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 560px;
  width: 100%;
`;

const Time = styled.div`
  text-align: center;
  font-size: 60px;
  small {
    display: block;
    font-size: 18px;
    font-weight: 100;
  }
`;

const TimeSeparator = styled.div`
  font-size: 60px;
`;

const Logo = styled.img`
  width: 157px;
`;

const Container = styled.div`
  color: #05fe01;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("${BG}");
  background-repeat: no-repeat;
  background-size: 100%;

  & * {
    font-family: 'Furore', sans-serif;
  }

  @media screen and (max-width: 600px) {
    ${Title} {
      font-size: 30px;
    }
    ${Time} {
      font-size: 40px;
      small {
        font-size: 12px;
      }
    }
    ${LineRadioative} {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 500px) {
    ${Title} {
      font-size: 20px;
    }
  }
`;

const calculateTimeLeft = () => {
  const now = new Date().getTime();
  const release = new Date("05/25/2022 10:00:00 GMT-04:00").getTime();
  const difference = release - now;
  const timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  
  const format = (num) => {
    const numFixed = Math.floor(num);
    return numFixed > 9 ? `${numFixed}` : `0${numFixed}`;
  };

  if (difference > 0) {
    timeLeft.days = format(difference / (1000 * 60 * 60 * 24));
    timeLeft.hours = format((difference / (1000 * 60 * 60)) % 24);
    timeLeft.minutes = format((difference / 1000 / 60) % 60);
    timeLeft.seconds = format((difference / 1000) % 60);
  }

  return timeLeft;
};

const FalloutCountdownHero = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div style={{ background: "#121212" }}>
      <Container>
        <Centered>
          <Logo src={LOGO} />
          <TitleWrap>
            <RadioativeImg src={RADIOATIVE} />
            <Title>FALLOUT IMMINENT</Title>
            <RadioativeImg src={RADIOATIVE} />
          </TitleWrap>
          <TimeWrap>
            <Time>
              {timeLeft.days}
              <small>DAYS</small>
            </Time>
            <TimeSeparator>:</TimeSeparator>
            <Time>
              {timeLeft.hours}
              <small>HOURS</small>
            </Time>
            <TimeSeparator>:</TimeSeparator>
            <Time>
              {timeLeft.minutes}
              <small>MINUTES</small>
            </Time>
            <TimeSeparator>:</TimeSeparator>
            <Time>
              {timeLeft.seconds}
              <small>SECONDS</small>
            </Time>
          </TimeWrap>
        </Centered>
        <LineRadioative />
        <FalloutSectionItems />
        {/* <FalloutSectionBuy /> */}
      </Container>
    </div>
  );
};

export default FalloutCountdownHero;
