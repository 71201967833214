import { Link } from "react-router-dom";
import styled from "styled-components";

import LOGO from "../static/images/JF2.png";

import BG_IMG from "../static/images/banner.jpg";

export const Container = styled.div`
  background-image: url(${BG_IMG});
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Inner = styled.div`
  max-width: 700px;
  padding: 60px 80px;
  margin: 40px;
  background: black;
  border-radius: 20px;
  @media (max-width: 600px) {
    margin: 10px;
    padding: 30px 40px;
    font-size: 14px;
    border-radius: 10px;
  }
`;

export const Title = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 700;
  margin: 40px 0;
  @media (max-width: 350px) {
    font-size: 32px;
  }
`;
export const Subtitle = styled.div`
  font-size: 20px;
  margin: 30px 0;
  color: white;
  font-weight: 500;
  @media (max-width: 350px) {
    font-size: 16px;
  }
`;
export const Text = styled.div`
  color: white;
  margin: 20px 0;
  line-height: 2;
  word-break: break-word;
  text-align: left;
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const InfoLink = styled.a`
  text-decoration: underline;
  color: white;
`

const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50%;
`

const VehicleForChange = () => {
  return (
    <Container>
      <Inner>
        <img
          src={LOGO}
          alt=""
          style={{
            height: "150px",
            margin: "0 auto 0 auto",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://junglefreaks.com";
          }}
        />
        <Title>A Vehicle for Change</Title>
        <Text>
          The Jungle Freaks team and community is committed to be a Vehicle for Change.
          This means that we take great pride in fostering a community of equality,
          diversity, and inclusion, both online and in real life. This page is dedicated
          to keeping the community up to date on past, present, and future plans the
          Jungle Freaks have to make an impact in the world.
        </Text>
        <Text>
          The Jungle Freaks team believes that the legacy systems of the world
          have created systems of inequity and division. We believe that in
          modern society, especially in NFTs and Crypto, we have a chance to
          level the playing field and create a greater sense of equality and
          unity in the world.
        </Text>
        <Text>
          In being this vehicle for change, the team and the community has made
          it a core value to do everything it can to bring about positive
          change. The team and community will continue to foster creative,
          impactful, and meaningful ways to bring about change.
        </Text>
        <Text>
          The JF DAO will play a vital role in deciding which social causes will
          receive a percentage of proceeds generated from the project.
        </Text>
        {/* <Text>
          The JF DAO will play a vital role in distributing funds to important
          social causes.
        </Text> */}
        <Subtitle>
          Bridging Connections with Social Media
        </Subtitle>
        <Text>
          We use Twitter Spaces as a means to highlight individuals that would like to use our
          platform for amplification of their project, voice and/or experiences.
          We discuss Web3, NFTs, Blockchain, and more.
        </Text>
        <Subtitle>EVENTS</Subtitle>
        <Text>
          <b>1/20/2021</b> – Black and Brown Creators in the NFT Space
        </Text>
        <ul>
          <li>Hosted by: <InfoLink href="https://twitter.com/G1CSTB3">ES0TER1C</InfoLink> & <InfoLink href="https://twitter.com/tonythecloser_">Tony “The Closer” Robinson</InfoLink></li>
        </ul>
        <Text>
          <b>12/5/2021 </b>– Women in NFTs
        </Text>
        <ul>
          <li>Hosted by: <InfoLink href="https://twitter.com/theNFTbabes">The NFT Babes</InfoLink> &#38; <InfoLink href="https://twitter.com/heaven_computer">Heaven Computer</InfoLink> </li>
        </ul>
        <Subtitle>DONATIONS</Subtitle>
        <Text>
          <b>12/3/2021</b> - Florida Memorial University,
          a private historically black university, donation of $100,000 to the FinTech Blockchain program.
        </Text>
        <Image src="donation.jpg" />
        <Text>
          <b>11/4/21</b> - Breast Cancer awareness $7,000
        </Text>

        <Subtitle>SUPPORTING THE COMMUNITY</Subtitle>
        <Text>
          <b>10/21/2021</b> - In October of 2021 the discord was hacked and
          members were spammed with a fake mint page, ultimately resulting in
          the scammers taking over $120,000.
        </Text>
        <Text>
          Jungle Freaks refunded every single person that was scammed by these
          hackers.
        </Text>

        <Subtitle>COMMUNITY SUPPORTING COMMUNITY</Subtitle>
        <Text>
          The Jungle Freaks Community is loving and supportive of people both in
          and outside the community. This makes us one of the strongest
          communities in the NFT space. Every day there are words of support and
          congratulations between holders.
        </Text>
        <Text>
          <b>11/10/2021</b> - The community members banded together to raise
          funds to buy a Jungle Freak NFT for a member who had their NFT stolen
          in a hack
        </Text>
        <Image src="screenshot3.png" />
        <Text>
          <b>1/15/2022</b> - Community banded together to help one of our
          members whose loved one was suffering from cancer and was undergoing
          chemotherapy by raising $6,495
        </Text>
        <Text>
          <b>1/27/2022</b> - One of our top holders, Babar, helped out a member
          of the community who was scammed and lost $25,000 along with all of their NFTs.
          Babar gifted a Jungle Freak NFT to them.
        </Text>
        <Image src="screenshot2.png" />
        <Text>
          <b>2/16/2022</b> - Another member of our community was scammed.
          One of our bigger holders took it upon himself to buy and return the NFT.
        </Text>
        <Image src="screenshot1.png" />

        <Link to="/" style={{ color: "#F59E0C" }}>
          Back To Homepage
        </Link>
      </Inner>
    </Container>
  );
};

export default VehicleForChange;
