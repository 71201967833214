import React from "react";
import styled from "styled-components";
import meraki from "../static/images/meraki.png";
import netvrk from "../static/images/netvrk.png";
import polygonal from "../static/images/polygonal.png";
import Heading from "./Heading";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  & > div {
    margin: auto;
  }
`;

const GridItem = styled.div``;

const Container = styled.div`
  overflow-x: hidden;
  background-color: ${({ bg }) => bg ? bg : '#000'};
  color: #fff;
  padding: 0% 4% 4%;
  & img {
    max-width: 100%;
  }

  @media screen and (max-width: 900px) {
    & ${Grid} {
      grid-template-columns: 1fr 1fr;
    }
    & ${GridItem} {
      min-height: 140px;
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 600px) {
    & ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

export default function SectionPartners({ bg }) {
  return (
    <Container id="partners" bg={bg}>
      <Heading align="center" border={true}>PARTNERS</Heading>
      <Grid>
        <GridItem>
          <a href="https://www.merakistudios.eu/">
            <img src={meraki} alt="" />
          </a>
        </GridItem>

        <GridItem>
          <a href="https://www.netvrk.co/">
            <img src={netvrk} alt="" />
          </a>
        </GridItem>

        <GridItem>
          <a href="https://www.polygonalmind.com/">
            <img src={polygonal} alt="" />
          </a>
        </GridItem>
      </Grid>
    </Container>
  );
}
