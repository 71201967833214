import React from "react";
import styled from "styled-components";
import LOGO from "../static/images/logo.svg";
import { Link } from "react-router-dom";
import HAMBURGER from "../static/images/icons/hamburger-icon.svg";

const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  padding-left: 4%;
  padding-right: 4%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left_header right_header right_header";
  align-items: center;
  position: relative;
  z-index: 2;

  ${({ absolute }) =>
    absolute &&
    `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  `}

  * {
    font-family: "Montserrat", sans-serif !important;
  }
`;

export default function Navbar({ absolute = false }) {
  function toogleMenu() {
    let menu_toggle = document.getElementById("toogle-menu");

    if (menu_toggle.style.display === "none") {
      menu_toggle.style.display = "block";
      menu_toggle.style.transition = "0.4";
      menu_toggle.style.maxHeight = "max-content";
    } else {
      menu_toggle.style.maxHeight = "0px";
      menu_toggle.style.display = "none";
    }
  }

  return (
    <Container absolute={absolute}>
      <Link to="/">
        <div className="head-logo">
          <img width="80px" src={LOGO} alt="" />
        </div>
      </Link>
      <div className="menu-header-grid">
        <div className="hamburger" onClick={() => toogleMenu()}>
          <img src={HAMBURGER} alt="" />
        </div>

        <nav id="toogle-menu" class="head-menu">
          <ul>
            <li>
              <Link to="/vehicle-for-change">
                <button>A VEHICLE FOR CHANGE</button>
              </Link>
            </li>
            <li>
              <a href="https://junglefreaks.io/#roadmap">
                <button>ROADMAP</button>
              </a>
            </li>
            <li>
              <a href="https://medium.com/@junglefreaksnft">
                <button>BLOG</button>
              </a>
            </li>
            <li>
              <a href="https://junglefreaks.io/#jungle">
                <button>$JUNGLE</button>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://shop.junglefreaks.io/"
              >
                <button>SHOP</button>
              </a>
            </li>
            {/* <li>
              <Link to="/info">
                <button>JFF</button>
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </Container>
  );
}
