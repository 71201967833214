import React, { useState } from "react";
import styled from "styled-components";
import VIDEO_THUMB from "../static/images/fallout-video-thumb.png";
import RADIOATIVE from "../static/images/radioative.png";
import McModal from "./McModal";

const VideoWrap = styled.div``;

const TextWrap = styled.div``;

const VideoThumbImg = styled.img`
  max-width: 100%;
`;

const Title = styled.div`
  color: #05fe01;
  font-size: 30px;
`;

const List = styled.div`
  font-size: 16px;
  color: #fff;
  padding-left: 50px;

  div {
    margin-top: 40px;
    position: relative;
  }

  img {
    width: 30px;
    position: absolute;
    top: 0;
    left: -40px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 30px;
  padding: 40px 8%;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 40px 0;

    ${TextWrap} {
      padding: 0 8%;
    }
    ${List} {
      padding: 0 0 0 8%;
    }
  }
`;

export default function FalloutSectionWhatIs() {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <Container>
        <VideoWrap>
          <VideoThumbImg
            src={VIDEO_THUMB}
            onClick={() => setModalVisible(true)}
            alt=""
          />
        </VideoWrap>
        <TextWrap>
          <Title>WHAT IS A FALLOUT FREAK?</Title>
          <List>
            <div>
              <img src={RADIOATIVE} alt="" />
              Fallout Freaks are made of 5,000 never-before-seen Fallout Test
              Subjects and 10,000 Fallout Crystal Freaks.
            </div>
            <div>
              <img src={RADIOATIVE} alt="" />
              Fallout Test Subjects have a 100% unique combination of traits.
              There are 3 different levels of rarity with several coveted LVL 3
              (1:1) Fallout Freaks.
            </div>
            <div>
              <img src={RADIOATIVE} alt="" />
              Fallout Crystals are the most sought after elements left on Earth.
              Expose your JF Genesis to one of 3 levels of rare radioactive
              Fallout Crystals and generate a mind-melting evolved version of
              your JF Genesis.
            </div>
          </List>
        </TextWrap>
      </Container>
      {modalVisible && (
        <McModal
          onClose={() => setModalVisible(false)}
          videoId="ea93879620a189cbb5d40fd37b9628fd"
        />
      )}
    </>
  );
}
