import styled from "styled-components";
import CLOSE from "../static/images/icons/close-white.svg";
import Button from "./Button";

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ModalButton = styled(Button)`
  ${({ color }) => color && 'background-color: ' + color + ';'}
  ${({ textColor }) => textColor && 'color: ' + textColor + ';'}
`;

const ModalContent = styled.div`
  width: 70%;
  background-color: #fff;
  position: relative;
`;

const CloseImg = styled.img`
  position: absolute;
  left: 5px;
  top: 5px;
  width: 35px;
  padding: 5px;
  opacity: 0.9;
  cursor: pointer;
  z-index: 1;
`;

const ModalHeader = styled.div`
  padding: 10px;
`;

const ModalBody = styled.div`
  position: relative;
`;

const ModalFooter = styled.div`
  padding: 10px;
  text-align: right;
`;

const Modal = ({
  onClose,
  title,
  buttons,
  children,
  contentStyle = {},
  bodyStyle = {},
}) => {
  return (
    <ModalContainer>
      <ModalContent style={contentStyle}>
        <CloseImg src={CLOSE} onClick={() => onClose(false)} />
        {title && <ModalHeader></ModalHeader>}
        <ModalBody style={bodyStyle}>{children}</ModalBody>
        {buttons && !!buttons.length && (
          <ModalFooter>
            {buttons.map((b, i) => (
              <ModalButton
                key={i}
                color={b.color}
                textColor={b.textColor}
                onClick={() => b.onClick && b.onClick()}
              >
                {b.text}
              </ModalButton>
            ))}
          </ModalFooter>
        )}
      </ModalContent>
    </ModalContainer>
  )
};

export default Modal;