import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 286px;
  height: 32px;
  display: flex;
  align-items: center;
  margin: 30px 0;
  & svg {
    position: absolute;
    top: 0;
    z-index: 0;
  }
`;

const Text = styled.div`
  color: ${({ color }) => color};
  z-index: 2;
  position: relative;
  font-weight: 800;
  font-size: 20px;
  padding-left: 16px;
`;

const Flag = ({ text, color, textColor = "#000" }) => (
  <Container>
    <Text color={textColor}>
      {text}
    </Text>
    <svg width="287" height="32" viewBox="0 0 287 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M286.5 0H0.5V32H286.5L270.819 16L286.5 0Z" fill={color} />
    </svg>
  </Container>
);

export default Flag;