import styled from "styled-components";
import Footer from "../components/Footer";
import FalloutCountdownHero from "../components/FalloutCountdownHero";
import Navbar from "../components/Navbar";

const Container = styled.div`
  background: #121212;
`;

const FalloutCountdown = () => {
  return (
    <Container>
      <header>
        <Navbar absolute={true} />
        <FalloutCountdownHero />
      </header>
      <Footer bg="transparent" cbBg="#05FE01" cbColor="#000" />
    </Container>
  );
};

export default FalloutCountdown;
