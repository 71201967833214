import React from "react";
import styled from "styled-components";
import Socials from "./Socials";
import LOGO from "../static/images/logo.svg";
import LL4 from "../static/images/LL4.png";
import logoEtherscan from "../static/images/etherscan-logo-light-circle.svg";

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const BoldedSmallText = styled.p`
  font-size: 20px;
  font-weight: 800;
`;

const SubscribeContainer = styled.form`
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  margin-bottom: 25px;

  & > input {
    width: 100%;
  }
  & > button {
    background-color: black;
    color: white;
    padding: 11px 10px;
    margin: 8px 0;
    border: 1px white solid;
  }
`;

const AreaLogo = styled.div`
  grid-area: area-logo;
  display: flex;  
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Caption = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 14px;
  margin-top: 20px;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  & img {
    margin-right: 10px;
  }

`;

const AreaPolicy = styled.div`
  grid-area: area-policy;
  & ${SmallText} {
    margin-bottom: 20px;
  }
`;

const AreaSubscribe = styled.div`
  grid-area: area-subscribe;
`;

const FooterCreatedBy = styled.div`
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bg }) => bg || "#E61E28"};
  color: ${({ color }) => color || "#fff"};
  font-size: 12px;
  margin-bottom: 10px;
  & img {
    width: 88px;
    max-height: 33px;
    object-fit: cover;
  }
`;

const FooterContainer = styled.div`
  background-color: ${({ bg }) => bg || "#000"};
  padding-bottom: 10px;
  & * {
    font-family: "Montserrat", sans-serif !important;
  }
`;

const SectionItemsWrapper = styled.div`
  display: grid;
  color: #fff;
  padding: 46px 4% 35px 4%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "area-logo area-logo area-policy area-subscribe";

  @media screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "area-logo"
      "area-subscribe"
      "area-policy";

    & ${BoldedSmallText} {
      margin-top: 40px;
    }
  }
`;

export default function Footer({ bg, cbBg, cbColor }) {
  const addr = process.env.REACT_APP_JFF_CONTRACT_ADDRESS;
  const add = addr.split("x")[1];
  const a = add.substring(0, 4);
  const b = add.substring(add.length - 4);
  const short = `0x${a}...${b}`;
  return (
    <FooterContainer bg={bg}>
      <SectionItemsWrapper>
        <AreaLogo>
          <Image>
            <a href="/">
              <img width="227px" src={LOGO} alt="" />
            </a>
            <Caption>
              <img width="20px" src={logoEtherscan} alt="" />
              <a href={`https://etherscan.io/address/${addr}`}>
                Contract: {short}
              </a>
            </Caption>
          </Image>
        </AreaLogo>

        <AreaPolicy>
          <p>
            <BoldedSmallText>POLICY</BoldedSmallText>
            <SmallText>
              <a
                href="mailto:admin@lochnesslabs.io"
                style={{ textDecoration: "none", color: "white" }}
              >
                Business Inquiries
              </a>
            </SmallText>
            <SmallText>
              <a
                href="/policy"
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy Policy
              </a>
            </SmallText>
            <SmallText>
              <a
                href="/terms"
                style={{ textDecoration: "none", color: "white" }}
              >
                Terms and Conditions
              </a>
            </SmallText>
          </p>
        </AreaPolicy>
        <AreaSubscribe>
          <p>
            <BoldedSmallText>SUBSCRIBE</BoldedSmallText>
            <SmallText>
              Subscribe to our newsletter, so that you can be the first to know
              about new offers and promotions.
            </SmallText>
          </p>
          <SubscribeContainer>
            <input
              type="text"
              placeholder="Email Address"
              id="fname"
              name="fname"
            />
            <button>SUBSCRIBE</button>
          </SubscribeContainer>
          <Socials />
        </AreaSubscribe>
      </SectionItemsWrapper>
      <FooterCreatedBy bg={cbBg} color={cbColor}>
        &copy; 2022 Lochness Labs
        <img src={LL4} alt="" />
      </FooterCreatedBy>
    </FooterContainer>
  );
}
