import React from "react";
import styled from "styled-components";
import HeroTitle from "./HeroTitle";
import SLIME from "../static/images/slime.png";
import ETHICON from "../static/images/eth-icon.svg";
import DISCORD from "../static/images/icons/discord.svg";
import FALLOUT_LOGO from "../static/images/fallout-logo.png";
import RADIOATIVE2 from "../static/images/radioative2.png";
import Flag from "./Flag";
import { ButtonAction } from "./Button";
import { Link } from "react-router-dom";

const GridText = styled.div`
  color: #fff;
  padding: 40px 0 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const McHeroParagraph = styled.div`
  font-size: 16px;
  margin: 23px 0 38px 0;
`;

const EthImgContainer = styled.div`
  & img {
    width: auto !important;
  }
`;

const ButtonWrapper = styled.div`
  & a {
    text-decoration: none;
  }
`;

const Grid = styled.div`
  display: flex;
  padding: 20px 8% 80px 8%;
  gap: 1vw;
  text-align: left;
  align-items: center;
  padding-top: 90px;
  justify-content: space-between;
`;

const Content = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  & ${HeroTitle} {
    margin-top: 9px;
  }
`;

const VertialLine = styled.div`
  width: 1px;
  background: #fff;
  height: calc(100% - 50px);
  position: absolute;
  left: -30px;
  top: 65px;
`;

const GridImg = styled.div`
  display: flex;
  align-items: center;
  max-height: 90%;
  position: relative;
  width: 30%;
`;

const RadioativeBg = styled.img`
  position: absolute;
  width: 140%;
  margin-left: -20%;
`;

const RadioativeBg2 = styled.img`
  display: none;
  width: 110%;
  margin-left: -5%;
  position: absolute;
`;

const FalloutMainImg = styled.img`
  padding: 20px 0;
  width: 100%;
`;

const ContainerFallout = styled(Container)`
  background-size: 774px;
  background-position: -55px top;
  background-repeat: repeat-x;
  background-image: url("${SLIME}");

  @media screen and (max-width: 900px) {
    ${Grid} {
      flex-direction: column;
    }
    ${VertialLine}, ${RadioativeBg} {
      display: none;
    }
    ${RadioativeBg2} {
      display: block;
    }
    ${GridText} {
      padding: 0;
    }
    ${GridImg} {
      margin-top: 80px;
      width: 40%;
    }
    ${ButtonWrapper} {
      margin: auto;
    }
  }
`;

const ButtonActionW = styled(ButtonAction)`
  width: auto;
  min-width: 282px;
  padding: 0 20px;
`;

// No longer coming soon, it's now live.
const FalloutHero = () => {
  return (
    <ContainerFallout>
      <Content>
        <Grid>
          <GridText>
            <VertialLine />
            <RadioativeBg2 src={RADIOATIVE2} alt="" />
            <EthImgContainer>
              <img src={ETHICON} alt="" />
            </EthImgContainer>
            <Flag text="NOW EVOLVING" color="#02FD05" />
            <HeroTitle>
              <small>JUNGLE FREAKS:</small>
              FALLOUT FREAKS
            </HeroTitle>
            <McHeroParagraph style={{ maxWidth: "620px" }}>
              A collection of 15,000 evolved Freaks who suffer side effects from
              heavy exposure to the universe's rarest radioactive crystals.
            </McHeroParagraph>
            <ButtonWrapper>
              <a href="https://mint.falloutfreaks.io/">
                <ButtonActionW
                  style={{ backgroundColor: "#02FD05", color: "#000" }}
                >
                  EVOLVE NOW
                </ButtonActionW>
              </a>
            </ButtonWrapper>
          </GridText>
          <GridImg>
            <RadioativeBg src={RADIOATIVE2} alt="" />
            <FalloutMainImg src={FALLOUT_LOGO} alt="" />
          </GridImg>
        </Grid>
      </Content>
    </ContainerFallout>
  );
};

export default FalloutHero;
