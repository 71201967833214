import React from "react";
import MICON from "../static/images/icons/m-icon.svg";
import DISCORD from "../static/images/icons/discord.svg";
import TWITTER from "../static/images/icons/twitter.svg";
import SOCIAL1 from "../static/images/icons/social1.svg";
import SOCIAL2 from "../static/images/icons/social2.svg";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Socials = () => {
  return (
    <Container>
      <a href="https://medium.com/@junglefreaksnft/">
        <img width="30px" src={MICON} alt="" />
      </a>
      <a href="https://discord.com/invite/MrZ2cTarmU">
        <img width="30px" src={DISCORD} alt="" />
      </a>
      <a href="https://twitter.com/JungleFreaksNFT">
        <img width="30px" src={TWITTER} alt="" />
      </a>
      <a href="https://opensea.io/collection/jungle-freaks-genesis">
        <img width="30px" src={SOCIAL1} alt="" />
      </a>
      <a href="https://looksrare.org/collections/0x7E6Bc952d4b4bD814853301bEe48E99891424de0">
        <img width="30px" src={SOCIAL2} alt="" />
      </a>
    </Container>
  );
};

export default Socials;